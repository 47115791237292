import { graphql } from 'gatsby';
import { useTitle, useMeta } from 'hooked-head';
import React from 'react';

import Hero from '../components/Hero';
import Layout from '../components/Layout';

import { Provider as ContextProvider } from '../context';

const HomePage = ({
  data: {
    site: {
      siteMetadata: { title, siteUrl },
    },
    hero: { frontmatter },
  },
}) => {
  const { excerpt, smImageTwitter, smImageFacebook } = frontmatter;

  useTitle(title);

  useMeta({
    name: 'og:title',
    content: title,
  });

  useMeta({
    name: 'twitter:title',
    content: title,
  });

  useMeta({
    name: 'og:description',
    content: excerpt,
  });

  useMeta({
    name: 'twitter:description',
    content: excerpt,
  });

  useMeta({
    name: 'twitter:card',
    content: smImageTwitter ? 'summary_large_image' : 'summary',
  });

  if (smImageTwitter || smImageFacebook) {
    if (smImageTwitter) {
      const smImageSrcTwitter =
        smImageTwitter.childImageSharp.gatsbyImageData.src;

      useMeta({
        name: 'twitter:image',
        content: `${siteUrl}${smImageSrcTwitter}`,
      });
    }

    if (smImageFacebook) {
      const smImageSrcFacebook =
        smImageFacebook.childImageSharp.gatsbyImageData.src;

      useMeta({
        name: 'og:image',
        content: `${siteUrl}${smImageSrcFacebook}`,
      });
    }
  }

  return (
    <ContextProvider value={{}}>
      <Layout
        menuTheme="white"
        burgerMenuTheme="white"
        titleTemplate={false}
        showLogo={false}
      >
        <Hero {...frontmatter} />
      </Layout>
    </ContextProvider>
  );
};

export default HomePage;

export const query = graphql`
  query HomePage($pageID: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }

    hero: markdownRemark(id: { eq: $pageID }) {
      frontmatter {
        ...Hero

        excerpt

        smImageTwitter: sm_image {
          childImageSharp {
            gatsbyImageData(
              layout: FIXED
              width: 1200
              height: 600
              transformOptions: { cropFocus: CENTER }
            )
          }
        }

        smImageFacebook: sm_image {
          childImageSharp {
            gatsbyImageData(
              layout: FIXED
              width: 1200
              height: 628
              transformOptions: { cropFocus: CENTER }
            )
          }
        }
      }
    }
  }
`;
