import { css } from '@emotion/react';

import { bodySpacing, fluid, fonts } from '../../style-tokens';

export const container = css`
  align-items: center;
  background: black;
  bottom: ${bodySpacing};
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: ${bodySpacing};
  position: absolute;
  right: ${bodySpacing};
  top: ${bodySpacing};
`;

export const logo = css`
  font-family: ${fonts.sans.family};
  font-size: ${fluid(3.75, 9)};
  font-weight: 700;
  left: -0.3rem;
  line-height: 1;
  margin: 0;
  position: absolute;
  top: ${fluid(-1, -1.25)};

  > *:first-child {
    left: ${fluid(-0.5, -0.75)};
    position: absolute;
    top: 85%;
    transform: rotate(-90deg) translateX(-107%);
    transform-origin: top left;
  }

  > *:last-child {
    left: ${fluid(0.4, 1.25)};
    position: relative;
  }
`;

export const excerpt = css`
  font-family: ${fonts.sans.family};
  font-size: ${fluid(1.85, 2.5)};
  font-weight: 700;
  max-width: 30rem;
  padding-left: ${fluid(1.5, 2.25)};
  padding-right: ${fluid(1.5, 2.25)};
  text-align: center;
  z-index: 10;

  @media (min-width: 2300px) {
    max-width: 50rem;
  }
`;

export const tagline = css`
  bottom: ${fluid(-0.75, -1)};
  font-family: ${fonts.sans.family};
  font-size: ${fluid(1.35, 1.85)};
  font-weight: 700;
  left: ${fluid(-0.35, -0.5)};
  margin: 0;
  max-width: 12rem;
  position: absolute;
  z-index: 10;
`;

export const imageContainer = css`
  left: 0;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  min-width: 100%;
`;

export const image = css`
  height: 100%;
`;
