import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

import {
  container,
  logo,
  excerpt as excerptStyle,
  tagline as taglineStyle,
  imageContainer as imageContainerStyle,
  image as imageStyle,
} from './hero.style';

const Hero = ({
  excerpt,
  background_image: { childImageSharp: backgroundImage },
}) => (
  <div css={container}>
    <div css={imageContainerStyle}>
      <GatsbyImage css={imageStyle} image={backgroundImage.gatsbyImageData} />
    </div>

    <h1 css={logo}>
      <span>Nach</span>
      <span>gefragt!</span>
    </h1>

    <p css={excerptStyle}>{excerpt}</p>

    <p css={taglineStyle}>
      Interviewreihe der Fach- und Netzwerkstellen Berlins
    </p>
  </div>
);

export default Hero;

export const fragment = graphql`
  fragment Hero on MarkdownRemarkFrontmatter {
    background_image {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          transformOptions: { grayscale: true }
        )
      }
    }
    excerpt
  }
`;
